import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom"

import { HomePage } from "./Pages/HomePage";
import { ProjectsPage } from "./Pages/ProjectsPage";
import { AboutPage } from "./Pages/AboutPage";
import { ExtrasPage } from "./Pages/ExtrasPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/works" element={<ProjectsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/extras" element={<ExtrasPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
