import React from "react";
import { NavLink } from "react-router-dom";
import ely from "../Images/ELY.png"
import giko from "../Images/gikogato.gif"




export function HomePage() {


    return (
        <div className="mx-auto d-flex flex-column justify-content-center align-items-center container" style={{ height: '100dvh', width: "400px" }}>
            <img src={giko} style={{ width: "50dvh" }}></img>
            <img src={ely} style={{ width: "50dvh" }}></img>
            <div className="row container mx-auto mt-2" >
                <NavLink to="/works" className="col text-decoration-none fw-bold fs-4 me-1"  >works</NavLink>
                <NavLink to="/about" className="col text-decoration-none fw-bold fs-4 me-1"  >about</NavLink>
                <a rel="noreferrer" target="_blank" href="http://www.shop.gikogato.net/" className="col text-decoration-none fw-bold fs-4 me-1">shop</a>
                {/* <NavLink to="/blog" className="col text-decoration-none fw-bold fs-5 me-1"  >blog</NavLink> */}
                <NavLink to="/extras" className="col text-decoration-none fw-bold fs-4"  >extras</NavLink>

            </div>
        </div >
    )
}