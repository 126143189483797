import React from "react";

import { NavBar } from "../components/NavBar";





export function ExtrasPage() {


    return (
        <div className="row justify-content-center">
            <div className="col-md-4 d-none project-panels">
                &#160;
            </div>
            <div className="col-md-4">
                <NavBar />
                <p className="display-1 text-center mt-5">COME BACK LATER</p>
            </div>
            <div className="col-md-4 d-none project-panels">
                &#160;
            </div>


        </div >
    )
}