import React from "react";

import { NavBar } from "../components/NavBar";
import theartist from "../Images/theartist.jpg"




export function AboutPage() {


    return (
        <div className="row justify-content-center mx-auto container px-0">
            <div className="col-md-4 d-none project-panels">
                &#160;
            </div>
            <div className="col-md-4 px-0" id="about-container">
                <NavBar />
                <div className=" mx-3 pt-3 mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <h1 className=" text-center fs-4 box-header  mb-0">what is GIKOGATO?</h1>
                            <p className=" mt-0 display-3 box-body  overflow-hidden py-1">
                                <p className="text-break w-auto fs-4 mt-2 mb-1">gikogato //ge-ko-ga-to// is the experience of girlhood. tough and cruel. polite and kind. gikogato is piloted by ari santiago.  </p>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <h1 className=" text-center fs-4 box-header  mb-0">contact me</h1>
                            <p className=" mt-0 display-3 box-body  overflow-hidden py-1">
                                <p className="text-break w-auto fs-4 mt-2 ">email me about commissions, projects, or send a love letter...</p>
                                <p className="text-break w-auto fs-2 mt-2 text-center"><a target="_blank" rel="noreferrer" href="mailto:gikogato@gmail.com">gikogato@gmail.com</a></p>

                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-75 mx-auto mb-3">
                    <h1 className=" text-center fs-4 box-header  mb-0">where 2 find me</h1>
                    <p className=" mt-0 display-3 box-body  overflow-hidden py-1">
                        <div className="row justify-content-center">
                            <p className="text-break w-auto fs-2 mt-2 text-center"><a target="_blank" rel="noreferrer" href="https://www.instagram.com/gikogato/">instagram</a></p>
                            <p className="text-break w-auto fs-2 mt-2 text-center"><a target="_blank" rel="noreferrer" href="https://twitter.com/gikogato?lang=en">twitter</a></p>
                        </div>
                        <p className="text-break w-auto fs-2 mt-2 text-center">@gikogato</p>
                    </p>
                </div>
                <div className="mb-5 mx-3">
                    <h1 className=" text-center fs-4 box-header  mb-0">about me...</h1>
                    <p className=" mt-0 display-3 box-body  overflow-hidden py-1">
                        <div className="row">
                            <img className="col-6 object-fit-scale" src={theartist} />
                            <div className="col-6">
                                <div className="row mt-2">
                                    <p className="ps-0 fw_bold text-decoration-underline text-break w-auto fs-4 col-3">birthday:</p><p className="text-break w-auto ps-0 fs-4 col-9">may 10th</p>
                                </div>
                                <div className="row mt-2">
                                    <p className="ps-0 fw_bold text-decoration-underline text-break w-auto fs-4 col-3">location:</p><p className="text-break w-auto ps-0 fs-4 col-9">philadelphia</p>
                                </div>
                                <div className="row mt-2">
                                    <p className="ps-0 fw_bold text-decoration-underline text-break w-auto fs-4 col-3">education:</p><p className="text-break w-auto ps-0 fs-4 col-9">uarts BFA in illustration</p>
                                </div>
                                <div className="row mt-2">
                                    <p className="ps-0 fw_bold text-decoration-underline text-break w-auto fs-4 col-3">trope:</p><p className="text-break w-auto ps-0 fs-4 col-9">final girl</p>
                                </div>
                                <div className="row mt-2">
                                    <p className="ps-0 fw_bold text-decoration-underline text-break w-auto fs-4 col-3">fave color:</p><p className="text-break w-auto ps-0 fs-4 col-9">pink</p>
                                </div>
                                <div className="row mt-2">
                                    <p className="ps-0 fw_bold text-decoration-underline text-break w-auto fs-4 col-3">fave book:</p><p className="text-break w-auto ps-0 fs-4 col-9">kamikaze girls</p>
                                </div>
                                <div className="row mt-2">
                                    <p className="ps-0 fw_bold text-decoration-underline text-break w-auto fs-4 col-3">fave film:</p><p className="text-break w-auto ps-0 fs-4 col-9">kamikaze girls</p>
                                </div>
                                <div className="row mt-2">
                                    <p className="ps-0 fw_bold text-decoration-underline text-break w-auto fs-4 col-3">collects:</p><p className="text-break w-auto ps-0 fs-4 col-9">stickers+pins+keychains</p>
                                </div>
                                <div className="row mt-2">
                                    <p className="ps-0 fw_bold text-decoration-underline text-break w-auto fs-4 col-3">fun fact:</p><p className="text-break w-auto ps-0 fs-4 col-9">i have cat ears...</p>
                                </div>

                            </div>
                        </div>
                    </p>
                </div>
            </div>
            <div className="col-md-4 d-none project-panels">
                &#160;
            </div>


        </div >
    )
}