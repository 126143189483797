import React from "react";

import { NavBar } from "../components/NavBar";
import { ProjectItem } from "../components/ProjectItem";

import aimthumb from "../Images/aimthumb.JPG"
import aimfull from "../Images/aimfull.JPG"
import beautfull from "../Images/beautifulfull.JPG"
import beautthumb from "../Images/beautifulthumb.JPG"
import personalitiesthumb from "../Images/bigpersonalitiesthumb.png"
import personalitiesfull from "../Images/bigpersonalitiesfull.PNG"
import bloodsugthumb from "../Images/bloodsugarthumb.PNG"
import bloodsugfull from "../Images/bloodsugarfull.PNG"
import paperfull from "../Images/paperfull.PNG"
import paperthumb from "../Images/paperthumb.PNG"
import parkfull from "../Images/parkfull.PNG"
import parkthumb from "../Images/parkthumb.PNG"
import ratedggfull from "../Images/ratedggfull.png"
import ratedggthumb from "../Images/ratedggthumb.jpg"
import twelvethumb from "../Images/12thumb.PNG"
import twelvefull from "../Images/12full.PNG"
import licoricethumb from "../Images/licoricethumb.PNG"
import licoricefull from "../Images/licoricefull.PNG"
import ugpthumb from "../Images/ugpthumb.PNG"
import ugpfull from "../Images/ugpfull.PNG"
import elythumb from "../Images/elythumb.PNG"
import elyfull from "../Images/elyfull.JPG"
import spiderlashes from "../Images/spiderlashesthumb+full.PNG"




export function ProjectsPage() {


    return (
        <div className="row justify-content-md-center mx-auto container px-0">
            <div className="col-md-4 d-none project-panels">
                &#160;
            </div>
            <div className="col-md-4 w-100 px-0">
                <NavBar />
                <div className="row px-2 pt-3">
                    <ProjectItem
                        order={"1"}
                        thumbimg={elythumb}
                        fullimg={elyfull}
                    />
                    <ProjectItem
                        order={"2"}
                        thumbimg={aimthumb}
                        fullimg={aimfull}
                    />
                    <ProjectItem
                        order={"3"}
                        thumbimg={beautthumb}
                        fullimg={beautfull}
                    />
                    <ProjectItem
                        order={"4"}
                        thumbimg={spiderlashes}
                        fullimg={spiderlashes}
                    />
                    <ProjectItem
                        order={"5"}
                        thumbimg={personalitiesthumb}
                        fullimg={personalitiesfull}
                    />

                    <ProjectItem
                        order={"6"}
                        thumbimg={paperthumb}
                        fullimg={paperfull}
                    />

                    <ProjectItem
                        order={"7"}
                        thumbimg={ratedggthumb}
                        fullimg={ratedggfull}
                    />
                    <ProjectItem
                        order={"8"}
                        thumbimg={bloodsugthumb}
                        fullimg={bloodsugfull}
                    />
                    <ProjectItem
                        order={"9"}
                        thumbimg={parkthumb}
                        fullimg={parkfull}
                    />

                    <ProjectItem
                        order={"10"}
                        thumbimg={ugpthumb}
                        fullimg={ugpfull}
                    />
                    <ProjectItem
                        order={"11"}
                        thumbimg={twelvethumb}
                        fullimg={twelvefull}
                    />
                    <ProjectItem
                        order={"12"}
                        thumbimg={licoricethumb}
                        fullimg={licoricefull}
                    />
                </div>
            </div>
            <div className="col-md-4 d-none project-panels">
                &#160;
            </div>


        </div >
    )
}