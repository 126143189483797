import React from "react";

export function ProjectItem(props) {

    return (
        <div className="col-6 col-md-4" >
            <img type="button" data-bs-toggle="modal" data-bs-target={"#gallery" + props.order} src={props.thumbimg} className="gallery-img  w-100" alt="galleryimage" />
            <div id={"gallery" + props.order} tabindex="-1" className="modal fade border-dark border-2" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" >
                        <a className="modal-body bg-none p-0 d-flex justify-content-center" data-bs-dismiss="modal">
                            <img className="modal-img mw-100" src={props.fullimg} alt="..." />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )

}