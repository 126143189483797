import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import giko from "../Images/gikogato.gif"
import appleunate from "../Images/appleunate.png"
import appleateimg from "../Images/appleate.png"


export function NavBar() {

    const [appleAte, setAppleAte] = useState(false)



    return (
        <nav class="navbar navbar-expand-lg px-2">
            <div class="container-fluid px-0">
                <div className="d-flex justify-content-between mx-auto d-md-none">
                    <img src={giko} alt="gikogato logo" id="giko" className="mb-md-4 w-75 object-fit-scale"></img>
                    <div>
                        <img onClick={() => { setAppleAte(!appleAte) }} src={appleAte ? appleateimg : appleunate} alt="gato hamburger " style={{ height: "8dvh" }} id="hamburger" className="object-fit-scale" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-expanded="false" aria-label="Toggle navigation"></img>
                    </div>
                </div>
                <a class="navbar-brand d-none d-md-block" href="/home">
                    <img src={giko} style={{ width: "30dvh" }}></img>
                </a>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <NavLink to="/works" className="col text-decoration-none fw-bold fs-3 me-2"  >works</NavLink>
                        </li>
                        <li class="nav-item">
                            <NavLink to="/about" className="col text-decoration-none fw-bold fs-3 me-2"  >about</NavLink>
                        </li>
                        <li class="nav-item">
                            <a rel="noreferrer" target="_blank" href="http://www.shop.gikogato.net/" className="col text-decoration-none fw-bold fs-3 me-2">shop</a>
                        </li>
                        {/* <li class="nav-item">
                            <NavLink to="/blog" className="col text-decoration-none fw-bold fs-3 me-2"  >blog</NavLink>
                        </li> */}
                        <li class="nav-item">
                            <NavLink to="/extras" className="col text-decoration-none fw-bold fs-3"  >extras</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )

}